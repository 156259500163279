import sitesQuery from '~/statamic/queries/sites';
import type { Site } from '~/statamic/types/site';

export const useStatamicSite = async (host: string): Promise<Site> => {
  const sites = await useStatamicSites();
  const site = sites.find(({ url }) => url === host);

  if (!site) {
    return sites[0];
  }

  return site;
};

export const useStatamicSites = async (): Promise<Site[]> => {
  const { data } = await useAsyncQuery<{sites: Site[]}>(sitesQuery);
  const sites = data?.value?.sites;

  if (!sites?.length) {
    throw new Error('Missing sites from Statamic.');
  }

  return sites;
};
