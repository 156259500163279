import validate from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import lock_45global from "/builds/sites/orangetalent.nl/frontend/middleware/lock.global.ts";
import preview_45global from "/builds/sites/orangetalent.nl/frontend/middleware/preview.global.ts";
import manifest_45route_45rule from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  lock_45global,
  preview_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}