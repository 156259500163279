export const globalQuery = gql`
  query getGlobalSet($handle: String!, $site: String!) {
    globalSet(handle: $handle, site: $site) {
      title
      handle
    }
  }
`;

export const globalsQuery = gql`
  query getGlobalSets {
    globalSets {
      title
      handle

      site {
        handle
      }

      ... on GlobalSet_Company {
        name
        email
        email_vacancies
        phone_number

        street
        house_number
        postal_code
        city

        street_secondary
        house_number_secondary
        postal_code_secondary
        city_secondary

        coc_number
        vat_number
        iban_number

        facebook
        instagram
        linkedin
      }

      ... on GlobalSet_Seo {
        site_name
        title_separator { value }
        og_image {
          responsiveAsset: responsiveAsset(width: 1200) { url alt width height }
        }
        twitter_handle
        trackers_local
        trackers_staging
        trackers_production
        use_google_site_verification
        google_site_verification
      }

      ... on GlobalSet_Redirects {
        redirects {
          url_old
          response { value }
          internal_url { url }
          external_url
        }
      }
    }
  }
`;
