import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowExternal, LazySvgoArrowLeft, LazySvgoArrowRight, LazySvgoCalendar, LazySvgoCheck, LazySvgoClock, LazySvgoEuro, LazySvgoFacebook, LazySvgoInstagram, LazySvgoLinkedin, LazySvgoMailO, LazySvgoMail, LazySvgoMute, LazySvgoPause, LazySvgoPhone, LazySvgoPin, LazySvgoPlay, LazySvgoPlus, LazySvgoTwitter, LazySvgoUnmute, LazySvgoUpload, LazySvgoWhatsapp } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowExternal", LazySvgoArrowExternal],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCheck", LazySvgoCheck],
["SvgoClock", LazySvgoClock],
["SvgoEuro", LazySvgoEuro],
["SvgoFacebook", LazySvgoFacebook],
["SvgoInstagram", LazySvgoInstagram],
["SvgoLinkedin", LazySvgoLinkedin],
["SvgoMailO", LazySvgoMailO],
["SvgoMail", LazySvgoMail],
["SvgoMute", LazySvgoMute],
["SvgoPause", LazySvgoPause],
["SvgoPhone", LazySvgoPhone],
["SvgoPin", LazySvgoPin],
["SvgoPlay", LazySvgoPlay],
["SvgoPlus", LazySvgoPlus],
["SvgoTwitter", LazySvgoTwitter],
["SvgoUnmute", LazySvgoUnmute],
["SvgoUpload", LazySvgoUpload],
["SvgoWhatsapp", LazySvgoWhatsapp],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
