import revive_payload_client_4sVQNw7RlN from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/builds/sites/orangetalent.nl/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_03YjkxYbK5 from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import plugin_tbFNToZNim from "/builds/sites/orangetalent.nl/frontend/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_eTVJQYlCmx from "/builds/sites/orangetalent.nl/frontend/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/builds/sites/orangetalent.nl/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import statamic_dpQ6Bs8KW4 from "/builds/sites/orangetalent.nl/frontend/statamic/plugins/statamic.ts";
import glsl_client_AwkQlOOBjp from "/builds/sites/orangetalent.nl/frontend/plugins/glsl.client.ts";
import preview_6oGpsaen2C from "/builds/sites/orangetalent.nl/frontend/plugins/preview.ts";
import scroll_smoother_client_w9lJDG6ngh from "/builds/sites/orangetalent.nl/frontend/plugins/scroll-smoother.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  nuxt_plugin_03YjkxYbK5,
  plugin_tbFNToZNim,
  plugin_eTVJQYlCmx,
  chunk_reload_client_UciE0i6zes,
  statamic_dpQ6Bs8KW4,
  glsl_client_AwkQlOOBjp,
  preview_6oGpsaen2C,
  scroll_smoother_client_w9lJDG6ngh
]