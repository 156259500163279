<template>
  <NuxtLoadingIndicator color="#ffffff" />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
useHead({
  script: [{
    children: `
      window.addEventListener("CookiebotOnDialogInit", function() {
        if (Cookiebot.getDomainUrlParam("CookiebotScan") === "true") {
          Cookiebot.setOutOfRegion();
        }
      });
    `,
  }],
});
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
  opacity: 0;
}
</style>
