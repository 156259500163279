<template>
  <main
    v-if="error.statusCode !== 404"
    id="main"
    class="grid place-content-center min-h-screen"
    tabindex="-1"
  >
    <div class="max-w-lg">
      <h1 class="text-2xl font-bold">
        Something went wrong.
      </h1>

      <p class="mt-4">
        An unexpected error has occurred, indicating an unforeseen issue
        that needs investigation and resolution.
      </p>
    </div>
  </main>
</template>

<script setup lang="ts">
const { error } = defineProps<{
  error: {
    url: string;
    data: any;
    message: string;
    statusCode: number;
    description: string;
    statusMessage: string;
  };
}>();

const { $statamic } = useNuxtApp();
const requestUrl = useRequestURL();

const { data } = await useAsyncData('redirects', () => $fetch('/api/redirects'));

let redirects = $statamic.global('redirects')?.redirects || [];

if (data.value?.redirects) {
  redirects = redirects.concat(data.value?.redirects || []);
}

const redirect = redirects?.find((item: any) => item.url_old === requestUrl.pathname);

if (redirect) {
  if (redirect.external_url) {
    navigateTo(redirect.external_url, {
      external: true,
      redirectCode: redirect.response?.value ?? 301,
    });
  }

  navigateTo(redirect.internal_url?.url, {
    redirectCode: redirect.response?.value ?? 301,
  });
}

onMounted(() => {
  if (error.statusCode === 404) {
    clearError({ redirect: '/404' });
  }
});
</script>
