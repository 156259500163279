// Generated by @hypernym/nuxt-gsap
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollSmoother } from 'gsap/ScrollSmoother';
import { SplitText } from 'gsap/SplitText';
gsap.registerPlugin(ScrollTrigger,ScrollToPlugin,ScrollSmoother,SplitText);


export const useGsap = gsap;
export const useScrollTrigger = ScrollTrigger;
export const useScrollToPlugin = ScrollToPlugin;
export const useScrollSmoother = ScrollSmoother;
export const useSplitText = SplitText;