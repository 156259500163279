import { translationsQuery } from '~/statamic/queries/translation';
import type { Translation } from '~/statamic/types/translation';

type TranslationsOptions = {
  file: string;
  language: string;
};

export const useStatamicTranslations = async (options: TranslationsOptions): Promise<Translation[]> => {
  const { data } = await useAsyncQuery<{lang: Translation[]}>(translationsQuery, options);
  const translations = data?.value?.lang;

  if (!translations) {
    throw new Error('Missing translations from Statamic.');
  }

  return translations;
};
