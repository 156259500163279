export default defineNuxtPlugin(() => {
  const scrollSmoother = useScrollSmoother.create({
    smooth: 0.75,
    effects: true,
    normalizeScroll: true,
  });

  if (isTouchDevice()) {
    scrollSmoother.kill();
  }

  return {
    provide: {
      scrollSmoother,
    },
  };
});
