import { globalQuery, globalsQuery } from '~/statamic/queries/global';
import type { Global } from '~/statamic/types/global';

type GlobalOptions = {
  site?: string;
  handle?: string;
};

export const useStatamicGlobal = async (options: GlobalOptions): Promise<Global> => {
  const { $statamic } = useNuxtApp();

  if (!options.site) {
    options.site = $statamic.site.handle;
  }

  const { data } = await useAsyncQuery<{globalSet: Global}>(globalQuery, options);
  const globalSet = data?.value?.globalSet;

  if (!globalSet) {
    throw new Error('Missing global set from Statamic.');
  }

  return globalSet;
};

export const useStatamicGlobals = async (): Promise<Global[]> => {
  const { data } = await useAsyncQuery<{globalSets: Global[]}>(globalsQuery);
  const globalSets = data?.value?.globalSets;

  if (!globalSets?.length) {
    throw new Error('Missing global sets from Statamic.');
  }

  return globalSets;
};
