export default gql`
  query getSites {
    sites {
      url
      name
      handle
      locale
      short_locale
    }
  }
`;
