export default defineNuxtRouteMiddleware((to, from) => {
  const { public: { lock } } = useRuntimeConfig();

  const lockPassword = useCookie('lock.password');
  const lockRedirectUrl = useCookie('lock.redirect_url', {
    default: () => '/',
  });

  if (from.query && from.query.lock_password) {
    if (from.query.lock_password === lock.password) {
      lockPassword.value = from.query.lock_password;
    }
  }

  if (lock.enabled === 'false' || lockPassword.value === lock.password) {
    return true;
  }

  if (from.path !== '/lock' && from.path !== 'service-worker.js') {
    lockRedirectUrl.value = from.path;
  }

  if (to.path === '/lock') {
    return true;
  }

  return navigateTo('/lock');
});
