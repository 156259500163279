export default defineNuxtPlugin(async () => {
  const site = await useStatamicSite(useRequestURL().origin);
  const globals = await useStatamicGlobals();
  const translations = await useStatamicTranslations({ file: 'site', language: site.short_locale });

  return {
    provide: {
      statamic: {
        site,
        trans: (key: string) => translationByKey(key, translations),
        global: (key: string) => globalByKeyForSite(key, globals, site),
      },
    },
  };
});
