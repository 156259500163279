import { default as indexzHghJkSNbvMeta } from "/builds/sites/orangetalent.nl/frontend/pages/[...slug]/index.vue?macro=true";
import { default as indexvJryQfF2nkMeta } from "/builds/sites/orangetalent.nl/frontend/pages/lock/index.vue?macro=true";
export default [
  {
    name: indexzHghJkSNbvMeta?.name ?? "slug",
    path: indexzHghJkSNbvMeta?.path ?? "/:slug(.*)*",
    meta: indexzHghJkSNbvMeta || {},
    alias: indexzHghJkSNbvMeta?.alias || [],
    redirect: indexzHghJkSNbvMeta?.redirect,
    component: () => import("/builds/sites/orangetalent.nl/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvJryQfF2nkMeta?.name ?? "lock",
    path: indexvJryQfF2nkMeta?.path ?? "/lock",
    meta: indexvJryQfF2nkMeta || {},
    alias: indexvJryQfF2nkMeta?.alias || [],
    redirect: indexvJryQfF2nkMeta?.redirect,
    component: () => import("/builds/sites/orangetalent.nl/frontend/pages/lock/index.vue").then(m => m.default || m)
  }
]