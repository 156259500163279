// @ts-ignore: Module has no types
import GlslCanvas from 'glslCanvas';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      glsl: GlslCanvas,
    },
  };
});
