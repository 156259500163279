import { HttpLink } from '@apollo/client/link/http/HttpLink';

export default defineNuxtRouteMiddleware(async (to) => {
  const apollo = useApollo();
  const config = useRuntimeConfig();

  // Create a URL object, this makes it easier to retrieve search params.
  const url = new URL(to.fullPath, 'http://test.com');

  // Retrieve the Statamic token from the URL
  const token = url.searchParams.get('token');

  // If Apollo clients are defined and a token is defined
  if (apollo.clients && apollo.clients.default && token) {
    // Create a completely new Apollo connection. The structure should match
    // the structure in nuxt.config.ts
    const link = new HttpLink({
      uri: `${config.public.statamic.url}/graphql?token=${token}`,
      headers: {
        'x-api-key': config.public.statamic.graphql.api ?? '',
      },
      fetchOptions: {
        // Not sure if necessary, but make sure the preview content is not cached.
        cache: 'no-store',
      },
    });

    // Overwrite the connection to Statamic on the Apollo client.
    apollo.clients.default.setLink(link);

    // Clear store will clear all caches and refetch active queries.
    // Just to be sure that the endpoint with token is used.
    await apollo.clients.default.resetStore();
  }
});
