import get from 'lodash/get';
import type { Site } from '../types/site';
import type { Global, Globals } from '../types/global';

export default (key: string, globals: Global[], site: Site) => {
  const globalsObject: {[key: string]: Globals} = {};

  globals
    .filter((set) => set.site.handle === site.handle)
    .forEach((set) => {
      globalsObject[set.handle] = set;
    });

  return get(globalsObject, key) as unknown;
};
